
import { Component, Vue } from 'vue-property-decorator';
import { searchIcon } from '../../utils/image-paths';

// Config Variables
import { ActiveTenant } from '../../../active-tenant';
import { tenants } from '../../../tenant-config';

@Component
export default class SearchLocations extends Vue {
  searchIconImage: string = searchIcon;
  currentTenant = tenants[ActiveTenant];
  textColor = tenants[ActiveTenant].text.offersForYouDescriptionColor;
  titleTextColor = tenants[ActiveTenant].text.offersForYouDescriptionTitleColor;
}
